<template>
  <div
    class="bgc-e8 bgc_full"
    style="
      min-height: 100vh;
      padding-top: 50px;
      padding-left: 45px;
      padding-right: 45px;
    "
  >
    <div class="bgc-e8">
      <div>
        <div class="row mt-5" id="CenterIndex">
          <div
            class="bgc_w mb_bgc_l back_g"
            v-show="
              $route.path != '/CenterIndex/data-manager' &&
              $route.path != '/CenterIndex/data-manager/upReport'
            "
          >
            <div class="pb-4">
              <div class="row justify-content-center mt-3 mb-1">
                <span class="d-inline-block custom-head"></span>
              </div>
              <div class="row justify-content-center mt-3" style="color: #000">
                <span class="fz-18">{{ userInfo.first_name }}</span>
              </div>
            </div>
            <div class="pb-4 mb_bgc_div">
              <div
                class="row justify-content-center mt-4"
                v-for="(t, index) in routerName"
                :key="index"
              >
                <a
                  @click="jump(t.r)"
                  :target="t.target == 'true' ? '_blank' : ''"
                  :class="[
                    t.class,
                    `/CenterIndex/${t.r}` == $router.history.current.path
                      ? 'active'
                      : '',
                    'a_box',
                  ]"
                  href="javascript:;"
                >
                  <span
                    style="
                      width: 28px;
                      display: inline-block;
                      text-align: center;
                    "
                    :class="'iconfont ' + t.icon"
                  ></span>
                  {{ t.title }}
                </a>
              </div>
            </div>
          </div>
          <div id="mb_jiben1">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const VM = require("@/components/VM.js").default;
import * as sesstion from "@/store/localStorage";
export default {
  data() {
    return {
      userInfo: sesstion.getLocalData("userData"),
      routerName: [
        // {
        //   title: "企业管理",
        //   r: "data-manager",
        //   class: "buy",
        //   active: "active",
        //   icon: "iconqiyeguanli",
        // },

        // {
        //   title: "购买服务",
        //   r: "/BuyService",
        //   class: "buy",
        //   active: "active",
        //   target: "false"
        // },

        // {
        //   title: "规则设置",
        //   r: "ruleSystem",
        //   active: "active",
        //   class: "rule",
        //   icon: "iconguizeshezhi",
        // },
        {
          title: "基本信息",
          r: "BasicInfro",
          class: "basic",
          active: "active",
          icon: "iconjibenxinxi",
        },
        {
          title: "查询记录",
          r: "FindRecord",
          class: "record",
          active: "active",
          icon: "iconchaxunjilu",
        },
        {
          title: "订单管理",
          r: "OrderManage",
          class: "order",
          active: "iconchaxunjilu",
          icon: "iconbaobiaoguanli",
        },
      ],
    };
  },
  created() {
    this.$store.state.headLogo = true;
    this.$store.state.showFooter = false;
  },
  methods: {
    jump(url) {
      if (url == "ruleSystem" && this.$store.state.powerUser == 1)
        return this.$Message.warning("您没有权限");
      this.$router.push("/CenterIndex/" + url);
      if (url == "data-manager") {
        VM.DataManager && VM.DataManager.onTab(0);
      }
      // this.$router.push(url)
    },
  },
};
</script>

<style scope>
#mb_jiben1 {
  width: 90%;
}
.back_g {
  background: url(../../assets/img/grbg.png) repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}
.bgc_full {
  height: 100%;
}
.mb_bgc_l {
  width: 10%;
}
#CenterIndex {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
#CenterIndex .a_box {
  color: #9a9a9a;
}
.page_head {
  background: url("../../assets/img/search/bg.png") repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.custom-head {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: url("../../assets/img/personal/user-head.png") no-repeat center
    center;
}
.user-vip {
  width: 16px;
  height: 16px;
  background-size: 100%;
}
.wh20 {
  width: 20px;
  height: 20px;
}
.basic,
.buy,
.record,
.order {
  color: #9a9a9a;
}
/* .basic > span {
  background: url("../../assets/img/personal/personal_prev.png") no-repeat
    center center;
} */
.active {
  color: #1172fe !important;
  background: #fff;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-left: 4px solid #1172fe;
}
/* .basic.active > span {
  background: url("../../assets/img/personal/personal_high.png") no-repeat
    center center;
} */

/* .buy > span {
  background: url("../../assets/img/personal/status-prev.png") no-repeat center
    center;
}
.buy.active > span {
  background: url("../../assets/img/personal/status-high.png") no-repeat center
    center;
}

.record > span {
  background: url("../../assets/img/personal/record-prev.png") no-repeat center
    center;
}
.record.active > span {
  background: url("../../assets/img/personal/record-high.png") no-repeat center
    center;
}

.order > span {
  background: url("../../assets/img/personal/order-prev.png") no-repeat center
    center;
}
.order.active > span {
  background: url("../../assets/img/personal/order-high.png") no-repeat center
    center;
}
.rule > span {
  background: url("../../assets/img/personal/ruler-alt.png") no-repeat center
    center;
}
.rule.active > span {
  background: url("../../assets/img/personal/ruler-alt-high.png") no-repeat
    center center;
} */
.footer {
  display: none;
}

@media (max-width: 560px) {
  .mb_bgc_l {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .mb_bgc_div {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 23px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgc-e8 bgc_full",staticStyle:{"min-height":"100vh","padding-top":"50px","padding-left":"45px","padding-right":"45px"}},[_c('div',{staticClass:"bgc-e8"},[_c('div',[_c('div',{staticClass:"row mt-5",attrs:{"id":"CenterIndex"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$route.path != '/CenterIndex/data-manager' &&
            _vm.$route.path != '/CenterIndex/data-manager/upReport'
          ),expression:"\n            $route.path != '/CenterIndex/data-manager' &&\n            $route.path != '/CenterIndex/data-manager/upReport'\n          "}],staticClass:"bgc_w mb_bgc_l back_g"},[_c('div',{staticClass:"pb-4"},[_vm._m(0),_c('div',{staticClass:"row justify-content-center mt-3",staticStyle:{"color":"#000"}},[_c('span',{staticClass:"fz-18"},[_vm._v(_vm._s(_vm.userInfo.first_name))])])]),_c('div',{staticClass:"pb-4 mb_bgc_div"},_vm._l((_vm.routerName),function(t,index){return _c('div',{key:index,staticClass:"row justify-content-center mt-4"},[_c('a',{class:[
                  t.class,
                  `/CenterIndex/${t.r}` == _vm.$router.history.current.path
                    ? 'active'
                    : '',
                  'a_box',
                ],attrs:{"target":t.target == 'true' ? '_blank' : '',"href":"javascript:;"},on:{"click":function($event){return _vm.jump(t.r)}}},[_c('span',{class:'iconfont ' + t.icon,staticStyle:{"width":"28px","display":"inline-block","text-align":"center"}}),_vm._v(" "+_vm._s(t.title)+" ")])])}),0)]),_c('div',{attrs:{"id":"mb_jiben1"}},[_c('router-view')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center mt-3 mb-1"},[_c('span',{staticClass:"d-inline-block custom-head"})])
}]

export { render, staticRenderFns }